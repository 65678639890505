'use client';

import { useState } from 'react';
import Button from '../../atoms/Button';

import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';
import Grid from '../Grid';
import Input from '../../atoms/Input';

// import { joinNewsletter } from "@/redux/user"

const JoinNewsletterForm = (): JSX.Element => {
    // const dispatch = useAppDispatch()
    // const dispatch = () => {}

    const initialState = { email: '' };
    const [formErrors, setFormErrors] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const setErrors = (newState: any) => {
        setFormErrors((prevState) => ({ ...prevState, ...newState }));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData(event.currentTarget);
        const email = formData.get('email')?.toString();

        let hasError = false;
        if (
            !email ||
            !email.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            )
        ) {
            setErrors({ email: 'You must enter a valid email address.' });
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        // const data = { email }

        // @ts-ignore
        // await dispatch(joinNewsletter({ data }))

        setDone(true);

        setLoading(false);
    };

    const onFormChange = () => {
        setErrors(initialState);
    };

    if (done) {
        return <Typography color="var(--green)">Thank you for subscribing!</Typography>;
    }

    return (
        <Box
            width="100%"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            onChange={onFormChange}
        >
            <Grid container display="flex" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={8} sm={10}>
                    <Input
                        required
                        name="email"
                        label="Email"
                        type="email"
                        id="email"
                        autoComplete="email"
                        aria-describedby="email-helper-text"
                        error={formErrors.email}
                    />
                </Grid>

                <Grid item xs={4} sm={2}>
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        margin="0.5rem 0 0 0"
                        loading={loading}
                    >
                        Join
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default JoinNewsletterForm;
